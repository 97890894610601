import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.png`} alt="" />
      </Link>
      <header>
        <h2>Randy Rey</h2>
        <p>
          <a href="mailto:reyrandy987@gmail.com">reyrandy987@gmail.com</a>
        </p>
      </header>
    </section>
    <section className="blurb">
      <h2>About</h2>
      <p>
        Hi, I&apos;m Randy, a senior software engineer with 7+ years of experience,
        passionate about building cool, scalable web apps. My go-to tools are React,
        Node.js, and ASP.NET, and I love diving into cloud technologies like AWS and
        Azure to solve complex challenges.
        When I’m not in the code trenches, I enjoy collaborating with teams, sharing ideas,
        and exploring the latest in tech. Outside of work, you might find me geeking Outside
        over new frameworks or just enjoying a good coffee.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? (
            <Link to="/resume" className="button">
              Learn More
            </Link>
          ) : (
            <Link to="/about" className="button">
              About Me
            </Link>
          )}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">
        &copy; Randy Rey <Link to="/">randyrey.com</Link>.
      </p>
    </section>
  </section>
);

export default SideBar;
